<template>
   <v-form ref="job-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Job detail</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.date" label="Date" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.user" label="User" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.technician" label="Technician" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="select" v-model="data.voucher_type" label="Voucher type" :options="dataOptions.voucherType" :readonly="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.voucher_category" label="Voucher category" :readonly="true" />
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl inputType="string" v-model="data.address" label="Address" :readonly="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <!-- <v-col cols="12">
            <v-btn color="purple darken-3 white--text" class="ma-2 mx-3 m-auto report-btn" large :href="reportUrl" target="_blank" :disabled="isFetching">Report download</v-btn>
         </v-col> -->

         <!-- <v-col cols="12">
            <v-row no-gutters>
               <v-btn color="primary" class="form-btn ma-2 m-auto px-8" large :disabled="isFetching" type="submit">Save</v-btn>
            </v-row>
         </v-col> -->

         <!-- <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" />
         </v-col> -->
      </v-row>
   </v-form>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
   name: 'JobManagementDetail',
   components: {
      FormControl,
   },
   data() {
      return {
         data: {
            id: _.toNumber(this.$route.params.id),
            date: '',
            user: '',
            technician: '',
            voucher_category: '',
            address: '',
            voucher_type: '',
         },
         dataOptions: {
            voucherType: [
               { text: '硬件檢查服務', value: 'hardware' },
               { text: '電腦維修服務', value: 'repair' },
               { text: '電腦螢幕檢查', value: 'screen' },
            ],
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest', 'showLoader'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      
      async initData() {
         this.showLoader(true)
         const resp = await this.sendRequest({ type: 'get_job_by_id', data: { id: this.data.id }, options: { noStatusSet: true }})
         if (!resp) {
            this.showLoader(false)
            return
         }
         const [userResp, technicianResp, voucherCategoryResp, voucherTypeResp] = await Promise.all([
            this.sendRequest({ type: 'get_user_by_id', data: { id: resp.data.user_id }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_user_by_id', data: { id: resp.data.technician_id }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_voucher_category_by_id', data: { id: resp.data.voucher_category }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_voucher_by_id', data: { id: resp.data.voucher_id }, options: { noStatusSet: true }}),
         ])
         this.showLoader(false)
         this.data = {
            ...this.data,
            date: moment(resp.data.date).format('YYYY-MM-DD HH:mm:ss'),
            address: resp.data.address,
            user: userResp.data?.username,
            technician: technicianResp.data?.username,
            voucher_category: voucherCategoryResp.data?.title,
            voucher_type: voucherTypeResp.data?.type,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['job-form'].validate()
         if (isValid) {
            const resp = await this.updateItem()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'jobManagement' } : null )
            })
            this.setShowDialog(true)
         }
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'job_data',
            id: this.data.id,
            // user_id: this.data.user,
            // technician_id: this.data.technician,
            // voucher_id: this.data.voucher,
            // voucher_category: this.data.voucher_category,
            address: this.data.address,
         }
         return await this.sendRequest({ type: 'update_job', data: formdata })
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
